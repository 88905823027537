@import "variables";
@import "Button";
body{
  
  min-height: 100vh;
  margin: 0;
  position: relative;
}
body {
  
  .error {
    color: rgba(0, 0, 0, 0.67);
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    text-align: center;
    border: 2px solid rgba(73, 73, 73, 0.685);
    overflow-y: auto;
    position: absolute;
    height: auto;
    width: auto;
    background-image: url(../img/404-img.png);
    background-size: 60% 50%;
    background-position: top center;
    background-repeat: no-repeat;
  }
  .intro {
    color: rgba(0, 0, 0, 0.67);
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    text-align: center;
    border: 2px solid rgba(73, 73, 73, 0.685);
    overflow-y: auto;
    position: absolute;
    height: auto;
    width: auto;
    background-image: url(../img/b2.png);
    background-size: 100% 640px;
    background-position: bottom right;
    background-repeat: no-repeat;
    .start-buttongroup {
      margin-top: 50px;
    }
    .reset-pass{
      margin-top: 25px; 
      margin-bottom: 5px;
    }

    .text-label-resetpass {
      margin: 30px;
      font-family: Raleway;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      text-align: center;

      color: rgba(0, 0, 0, 0.64);
    }
  }
  .App {
    
    color: rgba(0, 0, 0, 0.67);
    
    text-align: center;
    border: 2px solid rgba(73, 73, 73, 0.685);
    width: auto;
    overflow-y: auto;
    background-image: url(../img/rect861.png);
    background-size: 360px 640px;
    background-repeat: no-repeat;
    background-position: bottom right;
    position: absolute;
    height: auto;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
   
    .profile-username {
      display: flex;
      margin-top: 10px;
      align-items: flex-end;
      padding: 5px;
      justify-content: space-between;
    }
    *,
    * {
      margin: 0;
      /*padding: 0;*/
    }
    
    nav {
      position: fixed;
      bottom: 0;
      right: 0;
      margin-right:10px;
    }

    nav input {
      display: none;
    }
    label {
      position: relative;
    }

    nav label {
      position: absolute;
      right: 0;
      bottom: 0;
      z-index: 999;
      cursor: pointer;
    }
    nav label strong {
      padding: 0 10px;
      width: 40px;
      height: 40px;
      line-height: 40px !important;
      font: 700 14px Lato, helvetica, sans-serif;
      color: #fff;
      text-transform: uppercase;
      position: absolute;
      right: 0;
      bottom: 0;
      transition: color 250ms ease;
    }
    nav label b {
      width: 40px;
      height: 40px;
      display: block;
      position: relative;

      cursor: pointer;
      transition: background 500ms ease;
    }
    .nav-container{
      margin:20px;
    }
    nav label b i {
      display: block;
      background: rgba(0, 0, 0, 0.67);
      width: 24px;
      height: 4px;
      position: absolute;
      left: 8px;
      top: 11px;
      border-radius: 1000px;
      transform-origin: 20px 4px;
      transition: transform 300ms cubic-bezier(0.09, 0.92, 0, 1.435),
        opacity 500ms ease;
    }
    nav label b i:nth-child(2) {
      top: 18px;
    }
    nav label b i:nth-child(3) {
      transform-origin: 26px 4px;
      top: 25px;
    }

    nav li {
      margin: 0;
      list-style: none;
      width: 100%;
    }
    nav menu li:first-child {
      margin-top: 60px;
    }
    nav menu li:first-child a {
      border-top: rgba(255, 255, 255, 0.2) 1px solid;
    }
    nav li a {
      padding: 20px 10px;
      font: 100 18px Lato, helvetica, sans-serif;
      text-transform: uppercase;
      text-decoration: none;

      font-family: Raleway, sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 26px;
      line-height: 31px;

      /* identical to box height */
      text-align: right;

      color: #fafafa;

      display: block;
      border-bottom: rgba(0, 0, 0, 0.2) 1px solid;
    }

    nav menu li a:hover {
      background: rgba(0, 0, 0, 0);
    }
    nav #nav:checked ~ label strong {
      color: rgba(255, 255, 255, 0.25);
    }
    nav #nav:checked ~ label b {
      transition-delay: 100ms;
    }
    nav #nav:checked ~ label b i:nth-child(1) {
      transform: rotate(45deg) translate(6px, 11px);
    }
    nav #nav:checked ~ label b i:nth-child(2) {
      opacity: 0;
    }
    nav #nav:checked ~ label b i:nth-child(3) {
      transform: rotate(-45deg) translate(10px, -14px);
    }
    menu {
      display: none;
    }
    nav #nav:checked ~ menu {
      background: url(../img/Ellipse.png);
      height: 550px;
      margin: -10px;
      list-style: none;
      width: 355px;
      overflow: auto;
      transition: opacity 300ms ease;

      opacity: 1;
      padding: 20px 0;
      font: 100 18px Lato, helvetica, sans-serif;
      text-transform: uppercase;
      text-decoration: none;

      font-family: Raleway;
      font-style: normal;
      font-weight: bold;
      font-size: 26px;
      line-height: 31px;

      /* identical to box height */
      text-align: right;

      color: #e2dbdb;

      display: block;
      border-bottom: rgba(0, 0, 0, 0.2) 1px solid;
    }
    .margin {
      margin: 20px;
    }
   
    
    .circular-small {
      width: 40px;
      height: 40px;
      padding: 5px;
      margin: 5px;
      background: rgba(255, 255, 255, 0.79);
      border: 1px solid rgba(0, 0, 0, 0.5);
      border-radius: 100%;
      display: inline-block;
    }
    .edit-button {
      /* SAVE */

      font-family: Raleway;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 33px;
      cursor: pointer;

      /* identical to box height, or 206% */

      color: rgba(23, 140, 56, 0.49);
    }
    .inline-half {
      display: inline-block;
      width: 55%;
    }
    .select {
      border: 1px solid rgba(0, 0, 0, 0.16);
      box-sizing: border-box;
      border-radius: 100%;
      margin: 0 50px 0 50px;
    }
    .header-title {
      //padding-top: 20px;
      //padding-right: 100%;
      /*position: absolute;
      left:0;
      top:0;*/
      font-size: 1.75rem;
      text-align: left;      
    }
    .edit-header-title {
      padding-top: 20px;
      padding-right: 100%;
    }
    .header-logo {
      width: 100%;
      margin-left: 10px;
      margin-top: 20px;
    }
    .link {
      //text-decoration: none;
      color: rgb(68, 68, 68);
    }
    .circular-button {
      width: 50px;
      height: 50px;
      padding: 5px;
      margin: 20px;
      background: rgba(255, 255, 255, 0.79);
      border: 1px solid rgba(0, 0, 0, 0.5);
      border-radius: 100%;
      display: inline-block;
      
    }
   

    .choices {
      display: inline-block;
      width: 50%;
      height: 50%;

      vertical-align: top;
      align-content: stretch;
    }
    .choice {
      margin: 15px;
      
    }
    .choice-image {
      margin-right: 5px;
    }

    .menu {
      position: absolute;
      bottom: 8px;
      right: 16px;
      font-size: 18px;
    }
    .listgroup-item{
      padding:15px;
      background-color:transparent;
    }
  }
  .register {
    color: rgba(0, 0, 0, 0.67);
    bottom: 0;
    top: 0;
    left: 0;
    position: absolute;
    overflow-y: auto;
    height: auto;
    right: 0;
    text-align: center;
    border: 2px solid rgba(73, 73, 73, 0.685);
    width: auto;

  
  
  }
  .space-around {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .logo-front {
    width: 40%;
    height:40%;
  }
  .logo-front-small {
    margin-top: 10%;
    width: 25%;
    height: 80px;
  }
  .line-yellow {
    border: 1px solid #e4c22b;
    transform: rotate(0.17deg);
    width: 100%;
    //position: absolute;
    margin-top: 5px;
  }
  
  .motto {
    font-size: 24px;
  }
  
  .logo-front1 {
    width: 80px;
    height: 80px;
  }
  .checkbox {
    margin-right: 10px;
    background: #ffffff;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
  }
  
  .line-green {
    margin-left: 10%;
    margin-right: 10%;
    margin-bottom: 30px;
    border: 1px solid #178c38;
    transform: rotate(0.17deg);
  }
  
  /* Log In form */
  input {
    margin-bottom: 10px;
    margin-top: 10px;
    background: rgba(255, 255, 255, 0.79);
    border: 1px solid rgba(0, 0, 0, 0.5);
    box-sizing: border-box;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    text-indent: 5px;
    //border: 1px solid #000000 50%;
    
  }
  
  .text-label {
    font-family: Raleway;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
  
    /* identical to box height */
    text-align: center;
  
    color: rgba(0, 0, 0, 0.5);
  }
  .text-small {
    font-family: Raleway;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 19px;
  
    /* identical to box height */
    text-align: center;
  
    color: rgba(0, 0, 0, 0.5);
  }
  .text-medium {
    font-family: Raleway;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 19px;
  
    /* identical to box height */
    text-align: center;
  
    color: rgba(0, 0, 0, 0.5);
  }
  
  .inline {
    display: inline-block;
  }
  
  table {
    border-collapse: collapse;
  }
  
  td {
    width: 90px;
  }
  .center-screen {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 50%;
  }
  
  .lang-icon{
    width: 20px;
    height: 20px;
    margin-left:15px;
    margin-bottom: 4px;
  }
  
 
}



@media (max-height:2000px) {
  .iframe-container {
   
   .space-around {
     margin-bottom: 40px;
   }
  }
}

footer {
  position: relative;
    margin: 4.5em 0.9375em 0 !important;

  img {
    width: 210px;
  }
}


/*@media (max-height: 599px) {
  footer {
    position: absolute;
    bottom: 10px;

    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    position: relative;
    margin: 4.5em 0.9375em 0;
  }
}

@media (min-height: 600px) {
  footer {
    position: relative;
    margin: 4.5em 0.9375em 0;
  }
}*/