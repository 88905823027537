.button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 222px;
    height: 42px;
    transition: 0.3s;

    box-shadow: $button-shadow;
    border-radius: 12px;

    .button-text {
        text-decoration: none;
        color: $button-text-color;
        font-weight: 700;
    }
    .button-text:hover {
        text-decoration: none;
        /*color: $button-text-hover;*/
        font-weight: 900;
    }
    .button-text:focus {
        outline: none;
    }

    &:hover {
        opacity: .85;
    }
}